import React, { Fragment } from 'react';

import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import { Spotlight } from '@atlaskit/onboarding';
import { fg } from '@atlaskit/platform-feature-flags';

import {
	useConversationAssistantSpotlightActions,
	useConversationAssistantSpotlightState,
} from '../../controllers/spotlight';

import {
	SPOTLIGHT_ONBOARDING_STEP1_TARGET,
	SPOTLIGHT_ONBOARDING_STEP2_TARGET,
	SPOTLIGHT_ONBOARDING_STEP3_TARGET,
} from './constants';
import messages from './messages';

export const ConversationAssistantOnboardingSpotlight = ({
	openRovoChat,
	openAgentSelectorMenu,
	openConversationWithRovoChat,
}: {
	openRovoChat?: (event: React.MouseEvent<HTMLElement>, analyticsEvent: any) => void;
	openAgentSelectorMenu?: () => void;
	openConversationWithRovoChat?: () => void;
}) => {
	di(useConversationAssistantSpotlightState);

	const { formatMessage } = useIntl();
	const { next, end } = useConversationAssistantSpotlightActions();
	const { activeSpotlight } = useConversationAssistantSpotlightState();

	const renderActiveSpotlight = () => {
		const spotlights = [
			<Spotlight
				actionsBeforeElement={formatMessage(messages.onboardingStep1Progress)}
				actions={[
					{
						onClick: (event, analyticsEvent) => {
							if (openRovoChat) {
								openRovoChat(event, analyticsEvent);
							}
							next();
						},
						text: formatMessage(messages.next),
					},
					{ onClick: () => end(), text: formatMessage(messages.dismiss), appearance: 'subtle' },
				]}
				heading={formatMessage(messages.onboardingStep1Heading)}
				target={SPOTLIGHT_ONBOARDING_STEP1_TARGET}
				key={SPOTLIGHT_ONBOARDING_STEP1_TARGET}
				targetRadius={3}
			>
				{formatMessage(messages.onboardingStep1Description)}
			</Spotlight>,
			<Spotlight
				actionsBeforeElement={formatMessage(messages.onboardingStep2Progress)}
				actions={[
					{
						onClick: () => {
							if (openAgentSelectorMenu) {
								openAgentSelectorMenu();
							}
							next();
						},
						text: formatMessage(messages.next),
					},
					{ onClick: () => end(), text: formatMessage(messages.dismiss), appearance: 'subtle' },
				]}
				heading={formatMessage(messages.onboardingStep2Heading)}
				target={SPOTLIGHT_ONBOARDING_STEP2_TARGET}
				key={SPOTLIGHT_ONBOARDING_STEP2_TARGET}
				targetRadius={3}
				dialogPlacement="top center"
			>
				{formatMessage(messages.onboardingStep2Description)}
			</Spotlight>,
			<Spotlight
				actionsBeforeElement={formatMessage(messages.onboardingStep3Progress)}
				actions={[
					{
						onClick: () => {
							if (openConversationWithRovoChat) {
								openConversationWithRovoChat();
								end();
							}
						},
						text: formatMessage(messages.tryRovoHelper),
					},
					{ onClick: () => end(), text: formatMessage(messages.dismiss), appearance: 'subtle' },
				]}
				heading={formatMessage(messages.onboardingStep3Heading)}
				target={SPOTLIGHT_ONBOARDING_STEP3_TARGET}
				key={SPOTLIGHT_ONBOARDING_STEP3_TARGET}
				targetRadius={3}
			>
				{formatMessage(messages.onboardingStep3Description)}
			</Spotlight>,
		];

		if (!fg('rovo_chat_agents_spotlight_onboarding')) {
			return null;
		}

		if (activeSpotlight === null) {
			return null;
		}

		return spotlights[activeSpotlight];
	};

	return <Fragment>{renderActiveSpotlight()}</Fragment>;
};
