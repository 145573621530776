import React from 'react';

import { useIntl } from 'react-intl-next';

import { PrimaryButton } from '@atlaskit/atlassian-navigation';
import { type ButtonProps } from '@atlaskit/button';
import { SpotlightManager, SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';
import {
	ConversationAssistantOnboardingSpotlight,
	SPOTLIGHT_ONBOARDING_STEP1_TARGET,
	useConversationAssistantSpotlightActions,
	useConversationAssistantSpotlightState,
} from '@atlassian/conversation-assistant-onboarding';

import { RovoLogo } from '../../common/ui/rovo-logo';

import { messages } from './messages';

type Props = {
	testId?: string;
	isActive: boolean;
	onClick: ButtonProps['onClick'];
	tooltipContent?: React.ReactNode;
};

const defaultButtonStyles = {
	paddingInline: '8px',
};

const inactiveButtonStyles = {
	background: token('color.background.neutral'),
};

export const ConversationAssistantButton = ({
	testId,
	isActive,
	onClick,
	tooltipContent,
}: Props) => {
	const { formatMessage } = useIntl();
	const { start } = useConversationAssistantSpotlightActions();
	const { activeSpotlight } = useConversationAssistantSpotlightState();

	if (fg('rovo_chat_agents_spotlight_onboarding')) {
		return (
			<SpotlightManager blanketIsTinted={false}>
				<SpotlightTarget name={SPOTLIGHT_ONBOARDING_STEP1_TARGET}>
					<SpotlightPulse radius={3} pulse={activeSpotlight === null}>
						<PrimaryButton
							onMouseEnter={() => start()}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={{ ...defaultButtonStyles, ...(!isActive && inactiveButtonStyles) }}
							iconBefore={<RovoLogo size="medium" label="" primaryColor="currentColor" />}
							testId={testId}
							isSelected={isActive}
							onClick={onClick}
							tooltip={tooltipContent ?? formatMessage(messages.buttonLabel)}
						>
							{formatMessage(messages.buttonLabel)}
						</PrimaryButton>
					</SpotlightPulse>
					<ConversationAssistantOnboardingSpotlight
						openRovoChat={(event, analyticsEvent) => {
							if (!isActive && onClick) {
								onClick(event, analyticsEvent);
							}
						}}
					/>
				</SpotlightTarget>
			</SpotlightManager>
		);
	} else {
		return (
			<PrimaryButton
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ ...defaultButtonStyles, ...(!isActive && inactiveButtonStyles) }}
				iconBefore={<RovoLogo size="medium" label="" primaryColor="currentColor" />}
				testId={testId}
				isSelected={isActive}
				onClick={onClick}
				tooltip={tooltipContent ?? formatMessage(messages.buttonLabel)}
			>
				{formatMessage(messages.buttonLabel)}
			</PrimaryButton>
		);
	}
};
