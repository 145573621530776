import { defineMessages } from 'react-intl-next';

export default defineMessages({
	dismiss: {
		id: 'rovo-onboarding-dismiss',
		defaultMessage: 'Dismiss',
		description: 'Action button for rovo onboarding spotlight',
	},
	next: {
		id: 'rovo-onboarding-next',
		defaultMessage: 'Next',
		description: 'Action button for rovo onboarding spotlight',
	},
	tryRovoHelper: {
		id: 'rovo-onboarding-try-rovo-helper',
		defaultMessage: 'Try Rovo Helper',
		description: 'Action button for rovo onboarding spotlight',
	},
	onboardingStep1Heading: {
		id: 'rovo-onboarding-step-1-heading',
		defaultMessage: 'Harness company knowledge',
		description: 'Onboarding step 1 heading',
	},
	onboardingStep2Heading: {
		id: 'rovo-onboarding-step-2-heading',
		defaultMessage: 'Ask Rovo Chat almost anything',
		description: 'Onboarding step 2 heading',
	},
	onboardingStep3Heading: {
		id: 'rovo-onboarding-step-3-heading',
		defaultMessage: 'Elevate productivity by working with Rovo Agents',
		description: 'Onboarding step 3 heading',
	},
	onboardingStep1Description: {
		id: 'rovo-onboarding-step-1-description',
		defaultMessage:
			'Rovo Chat is an expert in your company. It knows the people, the projects, and the way things work.',
		description: 'Onboarding step 1 description',
	},
	onboardingStep2Description: {
		id: 'rovo-onboarding-step-2-description',
		defaultMessage:
			'Not sure where to begin? Try the conversation starters that change to match your context.',
		description: 'Onboarding step 2 description',
	},
	onboardingStep3Description: {
		id: 'rovo-onboarding-step-3-description',
		defaultMessage:
			'Rovo Agents are AI teammates with specialized skills and knowledge to help move your work forward.',
		description: 'Onboarding step 3 description',
	},
	onboardingStep1Progress: {
		id: 'rovo-onboarding-step-1-progress',
		defaultMessage: '1/3',
		description: 'Onboarding step 1 progress',
	},
	onboardingStep2Progress: {
		id: 'rovo-onboarding-step-2-progress',
		defaultMessage: '2/3',
		description: 'Onboarding step 2 progress',
	},
	onboardingStep3Progress: {
		id: 'rovo-onboarding-step-3-progress',
		defaultMessage: '3/3',
		description: 'Onboarding step 3 progress',
	},
});
